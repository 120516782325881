import React from 'react';
import { SettingsFormStore } from '@App/Service/Sensors/Details/Settings/SettingsFormStore';
import { DropdownField, InputField, SelectField, SwitchField, TextField } from '@Framework/Component/FormField';
import { Form } from '@Framework/Library/Form';
import { observer } from 'mobx-react';
import { SensorsModelStruct } from '@App/Service/Sensors/Model';
import { DefaultStruct } from '@App/Structures';
import { sensorIcons } from '@App/Assets/SensorIcons';

interface IProps {
    form : SettingsFormStore,
    saving : boolean,
    groups : DefaultStruct.IOption[],
    locations : DefaultStruct.IOption[],
    onSubmit : Function,
    onAddTag : Function,
}
interface IState {}

@observer
export class SettingsForm extends React.Component<IProps, IState> {
    
    public render() : React.ReactNode {
        return (
            <Form store={this.props.form} onSubmit={() => this.props.onSubmit()}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-6 col-md-2">
                            <div className="form-group">
                                <label>Icon</label>
                                <DropdownField
                                    id="iconId"
                                    disabled={this.props.saving}
                                    store={this.props.form.fields.iconId}
                                    options={Object.keys(sensorIcons).map((item, index) => ({
                                        value: String(index + 1),
                                        option: <><img src={sensorIcons[item]} height={22} alt=""/> Icon {index + 1}</>,
                                    }))}
                                />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-2">
                            <div className="form-group">
                                <label>Name</label>
                                <InputField disabled={this.props.saving} store={this.props.form.fields.name}/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-2">
                            <div className="form-group">
                                <label>Visual</label>
                                <InputField disabled={this.props.saving} store={this.props.form.fields.valueVisual}/>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="form-group">
                                <label>Priority</label>
                                <SelectField disabled={this.props.saving} store={this.props.form.fields.priorityId}>
                                    {SensorsModelStruct.sensorPriorities.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))}
                                </SelectField>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="form-group">
                                <label>Active</label>
                                <SwitchField disabled={this.props.saving} store={this.props.form.fields.isActive} wide={true}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <label>Group</label>
                                <div className="row m-0">
                                    <div className="col px-0">
                                        <SelectField disabled={this.props.saving} store={this.props.form.fields.groupId}>
                                            <option value=""></option>
                                            {this.props.groups.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </SelectField>
                                    </div>
                                    <div className="col-auto px-0">
                                        <button type="button" className="btn btn-success" onClick={() => this.props.onAddTag('groups')}>
                                            <i className="fa fa-plus" /> Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label>Location</label>
                                <div className="row m-0">
                                    <div className="col px-0">
                                        <SelectField disabled={this.props.saving} store={this.props.form.fields.locationId}>
                                            <option value=""></option>
                                            {this.props.locations.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </SelectField>
                                    </div>
                                    <div className="col-auto px-0">
                                        <button type="button" className="btn btn-success" onClick={() => this.props.onAddTag('locations')}>
                                            <i className="fa fa-plus" /> Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group m-0">
                                <label>Notes</label>
                                <TextField disabled={this.props.saving} store={this.props.form.fields.notes} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="row align-items-center">
                        <div className="col" />
                        <div className="col-auto">
                            {!this.props.form.isValid && <div className="form-notice text-danger"><i className="fa fa-exclamation-circle" /> Validation errors</div>}
                            {!this.props.form.isSaved && <div className="form-notice text-warning"><i className="fa fa-exclamation-triangle" /> Unsaved changes</div>}
                        </div>
                        <div className="col-auto">
                            <button type="submit" disabled={!this.props.form.isValid || this.props.form.isSaved || this.props.saving} className="btn btn-primary">
                                {this.props.saving ? <span className="spinner-border spinner-border-sm" /> : <i className="fa fa-check" />} Save
                            </button>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }
    
}