import React from 'react';
import { Details, ETab, SensorContext } from '@App/Service/Sensors/Details/Details';
import { SensorsModel, SensorsModelReq, SensorsModelStruct } from '@App/Service/Sensors/Model';
import { Loading } from '@Framework/Component/Loading';
import { Location } from '@Framework/Core/Location';
import { App } from '@Framework/Core/App';
import { ERangeType, IRangeProps, RangeSelector } from '@App/Component/RangeSelector';
import { Chart } from '@App/Service/Sensors/Details/Stats/Chart';
import { EModalSize, Link, Modal } from '@Framework/Factory';
import { Events } from '@App/Service/Sensors/Details/Stats/Events';
import moment from 'moment';
import { Tooltip } from '@Framework/Component/Tooltip';
import { Format } from '@App';
import { EPosition } from '@Framework/Component/Tooltip/Tooltip';
import { SensorTriggersModel, SensorTriggersStruct } from '@App/Service/Sensors/Details/Triggers/Model';
import { Model } from '@Framework/Library/Gateway';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
interface IProps {
    id : number,
    rangeType : ERangeType,
    dateFrom : number,
    dateTo : number,
}
interface IState {
    status : {
        stats : EStatus,
        triggers : EStatus,
        events : EStatus,
    },
    data : SensorsModelStruct.IStats,
    meta : SensorsModelStruct.IStatsMeta,
    triggers : SensorTriggersStruct.ITrigger[],
    hiddenTriggers : number[],
    triggersModalOpened : boolean,
    events : SensorsModelStruct.IEvent[],
}

export class Stats extends React.Component<IProps, IState> {

    private isMount : boolean = false;
    private limit : number = 100;
    private timer : NodeJS.Timeout = null;

    constructor(props) {
        super(props);
        this.state = {
            status: {
                stats: EStatus.Unloaded,
                triggers: EStatus.Unloaded,
                events: EStatus.Unloaded,
            },
            data: null,
            meta: null,
            triggers: [],
            hiddenTriggers: [],
            triggersModalOpened: false,
            events: [],
        };
    }

    public componentDidMount() {
        this.isMount = true;
        this.limit = Stats.getPointsLimit();
        this.loadData();
        this.loadTriggers();
        this.loadEvents();
        window.addEventListener('resize', this.onResize.bind(this));
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(this.props.id != prevProps.id) {
            clearTimeout(this.timer);
            this.loadData();
            this.loadTriggers();
            this.loadEvents();
        } else if(
            this.props.rangeType != prevProps.rangeType ||
            (this.props.rangeType == ERangeType.Custom && this.props.dateFrom != prevProps.dateFrom) ||
            (this.props.rangeType == ERangeType.Custom && this.props.dateTo != prevProps.dateTo)
        ) {
            clearTimeout(this.timer);
            this.loadData();
        }
    }

    public componentWillUnmount() : void {
        this.isMount = false;
        clearTimeout(this.timer);
        window.removeEventListener('resize', this.onResize);
    }

    public render() : React.ReactNode {
        const exportQuery = RangeSelector.getDateQuery(this.props);
        if(!exportQuery.to) exportQuery.to = moment().unix();
        const exportQueryStr = `from=${exportQuery.from}&to=${exportQuery.to}`;
        return (
            <>
                <Details
                    id={this.props.id}
                    tab={ETab.Stats}
                    controls={
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <SensorContext.Consumer>
                                    {sensor => sensor.status == EStatus.Loaded
                                        ? <Tooltip position={EPosition.Left} content={`Updated: ${moment.unix(sensor.data.tm).format(`${Format.Date.YN} ${Format.Time.S}`)}`}><em><i className="fa fa-refresh" /> {moment.unix(sensor.data.tm).fromNow()}</em></Tooltip>
                                        : <span className="spinner-border spinner-border-sm" />
                                    }
                                </SensorContext.Consumer>
                            </div>
                            <div className="col-auto">
                                <RangeSelector { ...this.props } onChange={props => this.onRangeChange(props)} />
                            </div>
                            <div className="col-auto">
                                <button type="button" disabled={this.state.status.triggers != EStatus.Loaded} className="btn btn-outline-primary btn-sm" onClick={() => this.setState({ triggersModalOpened: true })}>
                                    <i className="fa fa-eye" /> Triggers {this.state.status.triggers == EStatus.Loaded ? <span className="badge bg-secondary"></span> : <span className="spinner-border spinner-border-sm" />}
                                </button>
                            </div>
                            <div className="col-auto">
                                {(this.state.status.stats == EStatus.Loading || this.state.status.triggers == EStatus.Loading) && <Loading label="Loading chart..." />}
                                {(this.state.status.stats == EStatus.Failed || this.state.status.triggers == EStatus.Failed) && <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Stats loading error</div>}
                                {(this.state.status.stats == EStatus.Loaded && this.state.status.triggers == EStatus.Loaded) &&
                                    <a type="button" rel="noreferrer" target="_blank" href={`${Model.resources[0].origins[0]}devices/${this.state.meta.deviceId}/sensors/${this.props.id}/export?${exportQueryStr}`} className="btn btn-outline-primary btn-sm">
                                        <i className="fa fa-file-excel-o" /> Export
                                    </a>
                                }
                            </div>
                        </div>
                    }
                >
                    <div className="card-body">
                        {(this.state.status.stats == EStatus.Loading || this.state.status.triggers == EStatus.Loading) && <Loading label="Loading chart..." />}
                        {(this.state.status.stats == EStatus.Failed || this.state.status.triggers == EStatus.Failed) && <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Stats loading error</div>}
                        {(this.state.status.stats == EStatus.Loaded && this.state.status.triggers == EStatus.Loaded) &&
                            <Chart { ...this.props } { ...this.state } dateFrom={this.getQuery().from} />
                        }
                    </div>
                    <div className="card-header"><h6 className="m-0">Last events</h6></div>
                    {this.state.status.events == EStatus.Loading && <div className="card-body"><Loading label="Loading events..." /></div>}
                    {this.state.status.events == EStatus.Failed && <div className="card-body"><div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Events loading error</div></div>}
                    {this.state.status.events == EStatus.Loaded && <Events events={this.state.events} />}
                    <div className="card-footer text-end">
                        <Link url={`/sensors/${this.props.id}/events`}>
                            <a className="btn btn-sm btn-outline-primary">
                                <i className="fa fa-list" /> More events
                            </a>
                        </Link>
                    </div>
                </Details>
                {this.state.triggersModalOpened &&
                <Modal size={EModalSize.MD} onClickOutside={() => this.setState({ triggersModalOpened: false })}>
                    <div className="modal-header">
                        <h5 className="modal-title">Displayed triggers</h5>
                        <button type="button" className="btn btn-light btn-sm" onClick={() => this.setState({ triggersModalOpened: false })}>
                            <i className="fa fa-times"/>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.state.triggers.map(item => (
                            <div key={item.id} className="form-group">
                                <label className="form-check-inline">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={this.state.hiddenTriggers.indexOf(item.id) < 0}
                                        onChange={() => this.setState(state => {
                                            const hiddenTriggers = [ ...state.hiddenTriggers ];
                                            const index = hiddenTriggers.indexOf(item.id);
                                            if(index >= 0) {
                                                hiddenTriggers.splice(index, 1);
                                            } else {
                                                hiddenTriggers.push(item.id);
                                            }
                                            return { hiddenTriggers };
                                        })}
                                    /> {item.name}
                                </label>
                            </div>
                        ))}
                    </div>
                </Modal>
                }
            </>
        );
    }

    private loadData(reload : boolean = false) : void {
        (async () => {
            if(!reload) {
                this.setState(state => {
                    const status = { ...state.status };
                    status.stats = EStatus.Loading;
                    return { status };
                });
            }
            const res = await SensorsModel.stats(this.props.id, this.getQuery());
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.setState(state => {
                    const status = { ...state.status };
                    status.stats = EStatus.Loaded;
                    return {
                        status,
                        data: res.payload.data,
                        meta: res.payload.meta,
                    };
                });
                this.timer = setTimeout(() => this.loadData(true), res.payload.meta.dataMinStep * 1000);
            } else if(!reload) {
                this.setState(state => {
                    const status = { ...state.status };
                    status.stats = EStatus.Failed;
                    return { status };
                });
            }
        })();
    }

    private loadTriggers() : void {
        (async () => {
            this.setState(state => {
                const status = { ...state.status };
                status.triggers = EStatus.Loading;
                return { status };
            });
            const res = await SensorTriggersModel.list(this.props.id, { limit: 100 });
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.setState(state => {
                    const status = { ...state.status };
                    status.triggers = EStatus.Loaded;
                    return {
                        status,
                        triggers: res.payload.data || [],
                    };
                });
            } else {
                this.setState(state => {
                    const status = { ...state.status };
                    status.triggers = EStatus.Failed;
                    return { status };
                });
            }
        })();
    }

    private loadEvents() : void {
        (async () => {
            this.setState(state => {
                const status = { ...state.status };
                status.events = EStatus.Loading;
                return { status };
            });
            const res = await SensorsModel.events(this.props.id, { limit: 10 });
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.setState(state => {
                    const status = { ...state.status };
                    status.events = EStatus.Loaded;
                    return {
                        status,
                        events: res.payload.data || [],
                    };
                });
            } else {
                this.setState(state => {
                    const status = { ...state.status };
                    status.events = EStatus.Failed;
                    return { status };
                });
            }
        })();
    }

    private getQuery() : SensorsModelReq.Stats.IQuery {
        return {
            limit: this.limit,
            ...RangeSelector.getDateQuery(this.props),
        };
    }

    private onRangeChange(props : IRangeProps) : void {
        const query = { ...Location.params };
        query.rangeType = String(props.rangeType);
        if(props.rangeType == ERangeType.Custom) {
            query.dateFrom = String(props.dateFrom);
            query.dateTo = String(props.dateTo);
        }
        App.redirect(`/sensors/${this.props.id}?${Location.buildQuery(query)}`);
    }

    private onResize() : void {
        const limit = this.limit;
        this.limit = Stats.getPointsLimit();
        if(limit != this.limit) this.loadData();
    }

    private static getPointsLimit() : number {
        if(window.outerWidth <= 576) {
            return 20;
        } else if(window.outerWidth <= 768) {
            return 40;
        } else if(window.outerWidth <= 992) {
            return 60;
        } else return 100;
    }

}